<template>
    <div class="djt">
        <!--  地图页-->
        <div v-if="!isShow" class="dt">
            <div class="bg">
                <div class="top">
                    <div class="top-font1">2024年1月23日 星期五</div>
                    <div class="top-font2">
                        <img src="../../assets/img/canteenSelection/title.png" />
                    </div>
                    <div class="top-font3">
                        <img src="../../assets/img/canteenSelection/tq1.png" /> 多云转阴
                    </div>
                </div>
                <div class="body">
                    <div class="body1">
                        <div class="body1-1">
                            <p class="p1">智慧设备数</p>
                            <p class="p2">1234</p>
                        </div>
                        <div class="body1-2">
                            <p class="p1">智慧场景数</p>
                            <p class="p2">1234</p>
                        </div>
                        <div class="body1-3">
                            <p class="p1">今日实时在岗人数</p>
                            <p class="p2">1234</p>
                        </div>
                        <div class="body1-4">
                            <p class="p1">今日总留样数</p>
                            <p class="p2">1234</p>
                        </div>
                    </div>
                    <div class="body2">
                        <div class="body2-1">
                            <div class="wz" @click="getst">
                                <p>独五食堂</p>
                            </div>
                            <div class="tb"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--  地图页 end-->
        <!--  食堂页-->

        <div v-if="isShow" class="st">
            <div class="bg">
                <div class="top">
                    <div class="top-font1">2024年1月23日 星期五</div>
                    <div class="top-font2">
                        <img src="../../assets/img/canteenSelection/title.png" />
                    </div>
                    <div class="top-font3">
                        <img src="../../assets/img/canteenSelection/tq1.png" /> 多云转阴
                    </div>
                </div>
                <div class="body">
                    <div class="body1">
                        <div class="body1-1">
                            <p class="p1">实时智慧设备数</p>
                            <p class="p2">1234</p>
                        </div>
                        <div class="body1-3">
                            <p class="p1">今日实时在岗人数</p>
                            <p class="p2">1234</p>
                        </div>
                        <div class="body1-2">
                            <p class="p1">今日排班人员</p>
                            <p class="p2">1234</p>
                        </div>
                        <div class="body1-5">
                            <p class="p1">今日留样餐次</p>
                            <p class="p2">1234</p>
                        </div>
                        <div class="body1-4">
                            <p class="p1">今日总留样餐次</p>
                            <p class="p2">1234</p>
                        </div>
                    </div>
                    <div class="body2">
                        <div class="body2-1">
                            <div class="body2-1-img">
                                <div class="body2-1-img-1">
                                    <div>
                                        <div class="body2-1-img-1-1">
                                            <div class="body2-1-img-1-1-1">
                                                <p>留样餐次:<span>早餐</span></p>
                                                <p>留样时间:<span>2024/01/01</span></p>
                                            </div>
                                            <div class="body2-1-img-1-1-2">
                                                <p>留样数量:<span>1</span></p>
                                                <p>留样负责人:<span>李丹啊</span></p>
                                            </div>
                                        </div>
                                        <div class="body2-1-img-1-2">
                                            <div class="body2-1-img-1-2-1">
                                                <div class="body2-1-img-1-2-1-1">01</div>
                                                <div class="body2-1-img-1-2-1-2">20g/菜品名称</div>
                                                <div class="body2-1-img-1-2-1-3">
                                                    <img
                                                        src="../../assets/img/canteenSelection/image4.png"
                                                    />
                                                </div>
                                                <div class="body2-1-img-1-2-1-4">
                                                    <img
                                                        src="../../assets/img/canteenSelection/image5.png"
                                                    />
                                                </div>
                                            </div>
                                            <div class="body2-1-img-1-2-1">
                                                <div class="body2-1-img-1-2-1-1">02</div>
                                                <div class="body2-1-img-1-2-1-2">20g/菜品名称</div>
                                                <div class="body2-1-img-1-2-1-3">
                                                    <img
                                                        src="../../assets/img/canteenSelection/image4.png"
                                                    />
                                                </div>
                                                <div class="body2-1-img-1-2-1-4">
                                                    <img
                                                        src="../../assets/img/canteenSelection/image5.png"
                                                    />
                                                </div>
                                            </div>
                                            <div class="body2-1-img-1-2-1">
                                                <div class="body2-1-img-1-2-1-1">03</div>
                                                <div class="body2-1-img-1-2-1-2">20g/菜品名称</div>
                                                <div class="body2-1-img-1-2-1-3">
                                                    <img
                                                        src="../../assets/img/canteenSelection/image4.png"
                                                    />
                                                </div>
                                                <div class="body2-1-img-1-2-1-4">
                                                    <img
                                                        src="../../assets/img/canteenSelection/image5.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="body2-1-img-2">
                                    <dv-charts :option="option" class="body2-1-img-2-charts" />
                                </div>
                            </div>
                        </div>
                        <div class="body2-2">
                            <div class="body2-2-1"></div>
                            <div class="body2-2-2">
                                <div class="body2-2-2-img">
                                    <div class="body2-2-2-img-1">
                                        <div class="body2-2-2-img-1-1">
                                            <div class="body2-2-2-img-1-1-1">
                                                今日闯入 <br /><span>2</span> 次
                                            </div>
                                            <div class="body2-2-2-img-1-1-2">
                                                本月闯入 <br /><span>66</span> 次
                                            </div>
                                        </div>
                                        <div>
                                            <dv-scroll-board
                                                :config="config"
                                                class="body2-2-2-img-1-2"
                                            />
                                        </div>
                                    </div>
                                    <div class="body2-2-2-img-2">
                                        <swiper
                                            :options="swiperOption"
                                            class="body2-2-2-img-swiper"
                                        >
                                            <swiper-slide
                                                ><img
                                                    width="100%"
                                                    height="170"
                                                    src="../../assets/img/canteenSelection/image6.png"
                                            /></swiper-slide>
                                            <div class="swiper-pagination" slot="pagination"></div>
                                        </swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="body2-3">
                            <div class="body2-3-img">
                                <div class="body2-3-img-1">
                                    <div class="body2-3-img-1-1">
                                        <p style="color: #FFFFFF">17号冰箱</p>
                                        <p>编号：1293812312</p>
                                    </div>
                                    <div class="body2-3-img-1-2">
                                        <p>
                                            <img
                                                src="../../assets/img/canteenSelection/Vector.png"
                                            />温度：<span>12.2°C</span>（最高40°C、最低-20°C)
                                        </p>
                                        <p>
                                            <img
                                                src="../../assets/img/canteenSelection/Vector2.png"
                                            />湿度：<span>78.6%RH</span>（最高100%RH、最低10%RH)
                                        </p>
                                        <p>
                                            <img
                                                src="../../assets/img/canteenSelection/Vector3.png"
                                            />设备状态：<span>离线</span>
                                        </p>
                                        <p>
                                            <img
                                                src="../../assets/img/canteenSelection/Vector4.png"
                                            />设备地址：<span>苏大独墅五食堂</span>
                                        </p>
                                    </div>
                                    <div class="body2-3-img-1-3">
                                        <dv-charts
                                            :option="option2"
                                            class="body2-3-img-1-3-charts"
                                        />
                                    </div>
                                </div>
                                <div class="body2-3-img-2">
                                    <dv-capsule-chart
                                        :config="config2"
                                        class="body2-3-img-2-chart"
                                    />
                                </div>
                                <div class="body2-3-img-3">
                                    <div class="body2-3-img-3-1">
                                        <p>食堂负责人:<span>李丹啊</span></p>
                                        <p>联系电话:<span>13488888888</span></p>
                                    </div>
                                    <div class="body2-3-img-3-2">
                                        <img src="../../assets/img/canteenSelection/image7.png" />
                                        <img src="../../assets/img/canteenSelection/image8.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--  食堂页 end-->
    </div>
</template>
<script>
export default {
    name: 'canteenSelection',
    data() {
        return {
            isShow: false,
            swiperOption: {
                //自动进行轮播3.5s
                autoplay: 3500,
                //设置尺寸
                setWrapperSize: true,
                //监控轮播图下面的小白点
                pagination: '.swiper-pagination',
                //触发点击事件
                paginationClickable: true,
                //鼠标滑轮
                mousewheelControl: true,
                observeParents: true,
                //左右箭头
                prevButton: '.swiper-button-prev',
                nextButton: '.swiper-button-next',
                //设置轮播速度
                speed: 2000
            },
            option2: {
                title: {
                    text: '一周温度变化',
                    style: {
                        fill: 'rgba(170, 183, 202, 1)',
                        fontSize: 15
                    }
                },
                xAxis: {
                    name: '',
                    data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                    nameTextStyle: {
                        fill: 'rgba(170, 183, 202, 1)'
                    },
                    axisLabel: {
                        show: true,
                        style: {
                            fill: 'rgba(170, 183, 202, 1)'
                        }
                    }
                },
                yAxis: {
                    name: '数量(次)',
                    data: 'value',
                    nameTextStyle: {
                        fill: 'rgba(170, 183, 202, 1)'
                    },
                    axisLabel: {
                        show: true,
                        style: {
                            fill: 'rgba(170, 183, 202, 1)'
                        }
                    }
                },
                series: [
                    {
                        data: [1, 3, 6, 0, 5, 7, 1],
                        type: 'line',
                        smooth: true
                    }
                ]
            },
            option: {
                title: {
                    show: false
                },
                color: ['rgba(255, 141, 36, 1)', 'rgba(0, 191, 255, 1)', 'rgba(255, 255, 255, 1)'],
                xAxis: {
                    name: '',
                    data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                    nameTextStyle: {
                        fill: 'rgba(170, 183, 202, 1)'
                    },
                    axisLabel: {
                        show: true,
                        style: {
                            fill: 'rgba(170, 183, 202, 1)'
                        }
                    }
                },
                yAxis: {
                    name: '数量',
                    data: 'value',
                    nameTextStyle: {
                        fill: 'rgba(170, 183, 202, 1)'
                    },
                    axisLabel: {
                        show: true,
                        style: {
                            fill: 'rgba(170, 183, 202, 1)'
                        }
                    }
                },
                series: [
                    {
                        data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
                        type: 'line',
                        smooth: true
                    }
                ]
            },
            config2: {
                data: [
                    {
                        name: '南阳',
                        value: 167
                    },
                    {
                        name: '周口',
                        value: 67
                    },
                    {
                        name: '漯河',
                        value: 123
                    },
                    {
                        name: '郑州',
                        value: 55
                    },
                    {
                        name: '西峡',
                        value: 98
                    }
                ],
                showValue: false
            },
            config: {
                header: ['列1', '列2', '列3'],
                data: [
                    ['行1列1', '行1列2', '行1列3'],
                    ['行2列1', '行2列2', '行2列3'],
                    ['行3列1', '行3列2', '行3列3'],
                    ['行4列1', '行4列2', '行4列3'],
                    ['行5列1', '行5列2', '行5列3'],
                    ['行6列1', '行6列2', '行6列3'],
                    ['行7列1', '行7列2', '行7列3'],
                    ['行8列1', '行8列2', '行8列3'],
                    ['行9列1', '行9列2', '行9列3'],
                    ['行10列1', '行10列2', '行10列3']
                ],
                index: true,
                columnWidth: [50],
                align: ['center'],
                carousel: 'page',
                //透明色
                headerBGC: 'rgba(0,0,0,0)'
            }
        }
    },
    methods: {
        getst() {
            this.isShow = true
        }
    }
}
</script>
<style scoped lang="less">
.dt {
    .bg {
        background: url('../../assets/img/canteenSelection/11_00681.png') no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .top {
            width: 100%;
            height: 100%;
            background: url('../../assets/img/canteenSelection/Frame427320673.png') no-repeat;
            background-size: 100% 100%;
            width: 100%;
            height: 150 / @vh;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .top-font1 {
                font-size: 16 / @vh;
                color: rgba(170, 183, 202, 1);
                //相对定位位移
                position: relative;
                top: 25 / @vh;
                left: 30 / @vw;
            }
            .top-font2 {
                position: relative;
                top: 20 / @vh;
            }
            .top-font3 {
                font-size: 16 / @vh;
                color: rgba(170, 183, 202, 1);
                position: relative;
                top: 25 / @vh;
                right: 40 / @vw;
            }
        }
        .body {
            .body1 {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .body1-1 {
                    width: 200 / @vw;
                    height: 100 / @vh;
                    background: url('../../assets/img/canteenSelection/Component214.png') no-repeat;
                    background-size: 80 / @vw 80 / @vh;
                    margin-left: 400 / @vw;
                    .p1 {
                        font-size: 18 / @vh;
                        color: rgba(170, 183, 202, 1);
                        position: relative;
                        bottom: 10 / @vh;
                        left: 30 / @vw;
                    }
                    .p2 {
                        font-size: 40 / @vh;
                        color: rgba(220, 240, 255, 1);
                        position: relative;
                        bottom: 20 / @vh;
                        left: 30 / @vw;
                    }
                }
                .body1-2 {
                    .p1 {
                        font-size: 18 / @vh;
                        color: rgba(170, 183, 202, 1);
                        position: relative;
                        bottom: 10 / @vh;
                        left: 30 / @vw;
                    }
                    .p2 {
                        font-size: 40 / @vh;
                        color: rgba(220, 240, 255, 1);
                        position: relative;
                        bottom: 20 / @vh;
                        left: 30 / @vw;
                    }
                    width: 200 / @vw;
                    height: 100 / @vh;
                    background: url('../../assets/img/canteenSelection/Component215.png') no-repeat;
                    background-size: 80 / @vw 80 / @vh;
                }
                .body1-3 {
                    .p1 {
                        font-size: 18 / @vh;
                        color: rgba(170, 183, 202, 1);
                        position: relative;
                        bottom: 10 / @vh;
                        left: 30 / @vw;
                    }
                    .p2 {
                        font-size: 40 / @vh;
                        color: rgba(220, 240, 255, 1);
                        position: relative;
                        bottom: 20 / @vh;
                        left: 30 / @vw;
                    }
                    width: 200 / @vw;
                    height: 100 / @vh;
                    background: url('../../assets/img/canteenSelection/Component215.png') no-repeat;
                    background-size: 80 / @vw 80 / @vh;
                }
                .body1-4 {
                    .p1 {
                        font-size: 18 / @vh;
                        color: rgba(170, 183, 202, 1);
                        position: relative;
                        bottom: 10 / @vh;
                        left: 30 / @vw;
                    }
                    .p2 {
                        font-size: 40 / @vh;
                        color: rgba(220, 240, 255, 1);
                        position: relative;
                        bottom: 20 / @vh;
                        left: 30 / @vw;
                    }
                    width: 200 / @vw;
                    height: 100 / @vh;
                    background: url('../../assets/img/canteenSelection/Component215.png') no-repeat;
                    background-size: 80 / @vw 80 / @vh;
                    margin-right: 400 / @vw;
                }
            }
            .body2 {
                width: 1550 / @vw;
                height: 730 / @vh;
                margin: 0 auto;
                .body2-1 {
                    position: absolute;
                    top: 520 / @vh;
                    left: 1360 / @vw;
                    .wz {
                        width: 86 / @vw;
                        height: 41 / @vh;
                        background: url('../../assets/img/canteenSelection/Frame 427320256.png')
                            no-repeat;
                        background-size: 100% 100%;
                        position: relative; /* 设置相对定位以便使用伪元素 */
                        right: 32 / @vw;
                        bottom: 8 / @vh;
                        color: rgba(220, 240, 255, 1);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: background 1s ease-in-out; /* 设置渐变时间和效果 */
                    }
                    /* 定义鼠标移入时的样式 */
                    .wz:hover {
                        background: url('../../assets/img/canteenSelection/Frame 4273202562.png')
                            no-repeat; /* 鼠标移入后显示的背景图片 */
                        background-size: 100% 100%;
                        right: 32 / @vw;
                        bottom: 8 / @vh;
                        color: rgba(220, 240, 255, 1);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .tb {
                        width: 20 / @vw;
                        height: 71 / @vh;
                        background: url('../../assets/img/canteenSelection/Frame427320013.png')
                            no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
}
.st {
    .bg {
        background: url('../../assets/img/canteenSelection/image2.png') no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .top {
            width: 100%;
            height: 100%;
            background: url('../../assets/img/canteenSelection/Frame427320673.png') no-repeat;
            background-size: 100% 100%;
            width: 100%;
            height: 150 / @vh;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .top-font1 {
                font-size: 16 / @vh;
                color: rgba(170, 183, 202, 1);
                //相对定位位移
                position: relative;
                top: 25 / @vh;
                left: 30 / @vw;
            }
            .top-font2 {
                position: relative;
                top: 20 / @vh;
            }
            .top-font3 {
                font-size: 16 / @vh;
                color: rgba(170, 183, 202, 1);
                position: relative;
                top: 25 / @vh;
                right: 40 / @vw;
            }
        }
        .body {
            .body1 {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .body1-1 {
                    width: 200 / @vw;
                    height: 100 / @vh;
                    background: url('../../assets/img/canteenSelection/Component214.png') no-repeat;
                    background-size: 80 / @vw 80 / @vh;
                    margin-left: 400 / @vw;
                    .p1 {
                        font-size: 18 / @vh;
                        color: rgba(170, 183, 202, 1);
                        position: relative;
                        bottom: 10 / @vh;
                        left: 30 / @vw;
                    }
                    .p2 {
                        font-size: 40 / @vh;
                        color: rgba(220, 240, 255, 1);
                        position: relative;
                        bottom: 20 / @vh;
                        left: 30 / @vw;
                    }
                }
                .body1-2 {
                    .p1 {
                        font-size: 18 / @vh;
                        color: rgba(170, 183, 202, 1);
                        position: relative;
                        bottom: 10 / @vh;
                        left: 30 / @vw;
                    }
                    .p2 {
                        font-size: 40 / @vh;
                        color: rgba(220, 240, 255, 1);
                        position: relative;
                        bottom: 20 / @vh;
                        left: 30 / @vw;
                    }
                    width: 200 / @vw;
                    height: 100 / @vh;
                    background: url('../../assets/img/canteenSelection/Component215.png') no-repeat;
                    background-size: 80 / @vw 80 / @vh;
                }
                .body1-3 {
                    .p1 {
                        font-size: 18 / @vh;
                        color: rgba(170, 183, 202, 1);
                        position: relative;
                        bottom: 10 / @vh;
                        left: 30 / @vw;
                    }
                    .p2 {
                        font-size: 40 / @vh;
                        color: rgba(220, 240, 255, 1);
                        position: relative;
                        bottom: 20 / @vh;
                        left: 30 / @vw;
                    }
                    width: 200 / @vw;
                    height: 100 / @vh;
                    background: url('../../assets/img/canteenSelection/Component215.png') no-repeat;
                    background-size: 80 / @vw 80 / @vh;
                }
                .body1-5 {
                    .p1 {
                        font-size: 18 / @vh;
                        color: rgba(170, 183, 202, 1);
                        position: relative;
                        bottom: 10 / @vh;
                        left: 30 / @vw;
                    }
                    .p2 {
                        font-size: 40 / @vh;
                        color: rgba(220, 240, 255, 1);
                        position: relative;
                        bottom: 20 / @vh;
                        left: 30 / @vw;
                    }
                    width: 200 / @vw;
                    height: 100 / @vh;
                    background: url('../../assets/img/canteenSelection/Component214.png') no-repeat;
                    background-size: 80 / @vw 80 / @vh;
                }
                .body1-4 {
                    .p1 {
                        font-size: 18 / @vh;
                        color: rgba(170, 183, 202, 1);
                        position: relative;
                        bottom: 10 / @vh;
                        left: 30 / @vw;
                    }
                    .p2 {
                        font-size: 40 / @vh;
                        color: rgba(220, 240, 255, 1);
                        position: relative;
                        bottom: 20 / @vh;
                        left: 30 / @vw;
                    }
                    width: 200 / @vw;
                    height: 100 / @vh;
                    background: url('../../assets/img/canteenSelection/Component214.png') no-repeat;
                    background-size: 80 / @vw 80 / @vh;
                    margin-right: 400 / @vw;
                }
            }
            .body2 {
                width: 100%;
                height: 820 / @vh;
                display: flex;
                flex-direction: row;
                .body2-1 {
                    width: 22%;
                    height: 100%;
                    .body2-1-img {
                        width: 100%;
                        height: 100%;
                        background: url('../../assets/img/canteenSelection/Frame 427320156.png')
                            no-repeat;
                        background-size: 405 / @vw 820 / @vh;
                        margin-left: 15 / @vw;
                        margin-top: 1 / @vh;
                        .body2-1-img-1 {
                            .body2-1-img-1-1 {
                                color: rgba(170, 183, 202, 1);
                                display: flex;
                                flex-direction: row;
                                position: relative;
                                top: 80 / @vh;
                                left: 20 / @vw;
                                .body2-1-img-1-1-2 {
                                    margin-left: 20 / @vw;
                                    p {
                                        font-size: 18 / @vh;
                                        margin-bottom: 10 / @vh;
                                        span {
                                            color: rgba(220, 240, 255, 1);
                                        }
                                    }
                                }
                                .body2-1-img-1-1-1 {
                                    p {
                                        font-size: 18 / @vh;
                                        margin-bottom: 10 / @vh;
                                        span {
                                            color: rgba(220, 240, 255, 1);
                                        }
                                    }
                                }
                            }
                            .body2-1-img-1-2 {
                                width: 400 / @vw;
                                height: 300 / @vh;
                                margin-top: 90 / @vh;
                                display: flex;
                                flex-direction: column;
                                .body2-1-img-1-2-1 {
                                    position: relative;
                                    left: 20 / @vw;
                                    width: 90%;
                                    height: 100 / @vh;
                                    border: 1px solid rgba(85, 98, 119, 0.5);
                                    border-bottom: none;
                                    border-left: none;
                                    border-right: none;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    .body2-1-img-1-2-1-1 {
                                        width: 40 / @vw;
                                        height: 40 / @vh;
                                        background: rgba(124, 135, 151, 1);
                                        border-radius: 60%;
                                        color: #ffffff;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }
                                    .body2-1-img-1-2-1-2 {
                                        margin-left: 20 / @vw;
                                        color: #ffffff;
                                    }
                                    .body2-1-img-1-2-1-3 {
                                        margin-left: 30 / @vw;
                                        img {
                                            width: 90%;
                                            height: 90%;
                                        }
                                    }
                                    .body2-1-img-1-2-1-4 {
                                        margin-left: 10 / @vw;
                                        img {
                                            width: 90%;
                                            height: 90%;
                                        }
                                    }
                                }
                            }
                        }
                        .body2-1-img-2 {
                            width: 100%;
                            height: 330 / @vh;
                            .body2-1-img-2-charts {
                                width: 100%;
                                height: 90%;
                                position: relative;
                                left: 10 / @vw;
                                top: 30 / @vh;
                            }
                        }
                    }
                }
                .body2-2 {
                    width: 56%;
                    height: 100%;
                    .body2-2-1 {
                        width: 100%;
                        height: 65%;
                        display: flex;
                        flex-direction: row;
                        .body2-2-1-1 {
                            width: 100%;
                            display: flex;
                            .body2-2-1-img-1 {
                                width: 80 / @vw;
                                height: 35 / @vh;
                                background: url('../../assets/img/canteenSelection/Frame 427320256.png')
                                    no-repeat;
                                background-size: 100% 100%;
                                color: #c5c5c5;
                                font-size: 19 / @vh;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                top: 345 / @vh;
                                left: 10 / @vw;
                            }
                            .body2-2-1-img-2 {
                                width: 80 / @vw;
                                height: 35 / @vh;
                                background: url('../../assets/img/canteenSelection/Frame 427320256.png')
                                    no-repeat;
                                background-size: 100% 100%;
                                color: #c5c5c5;
                                font-size: 19 / @vh;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                top: 345 / @vh;
                                left: 120 / @vw;
                            }
                            .body2-2-1-img-3 {
                                width: 80 / @vw;
                                height: 35 / @vh;
                                background: url('../../assets/img/canteenSelection/Frame 427320256.png')
                                    no-repeat;
                                background-size: 100% 100%;
                                color: #c5c5c5;
                                font-size: 19 / @vh;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                        .body2-2-1-2 {
                            width: 100%;
                            display: flex;
                            .body2-2-2-img-1 {
                                width: 80 / @vw;
                                height: 35 / @vh;
                                background: url('../../assets/img/canteenSelection/Frame 427320256.png')
                                    no-repeat;
                                background-size: 100% 100%;
                                color: #c5c5c5;
                                font-size: 19 / @vh;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                top: 262 / @vh;
                                right: 10 / @vw;
                            }
                            .body2-2-2-img-2 {
                                width: 80 / @vw;
                                height: 35 / @vh;
                                background: url('../../assets/img/canteenSelection/Frame 427320256.png')
                                    no-repeat;
                                background-size: 100% 100%;
                                color: #c5c5c5;
                                font-size: 19 / @vh;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                top: 312 / @vh;
                                left: 10 / @vw;
                            }
                            .body2-2-2-img-3 {
                                width: 80 / @vw;
                                height: 35 / @vh;
                                background: url('../../assets/img/canteenSelection/Frame 427320256.png')
                                    no-repeat;
                                background-size: 100% 100%;
                                color: #c5c5c5;
                                font-size: 19 / @vh;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                top: 312 / @vh;
                                left: 25 / @vw;
                            }
                            .body2-2-2-img-4 {
                                width: 80 / @vw;
                                height: 35 / @vh;
                                background: url('../../assets/img/canteenSelection/Frame 427320256.png')
                                    no-repeat;
                                background-size: 100% 100%;
                                color: #c5c5c5;
                                font-size: 19 / @vh;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                top: 312 / @vh;
                                left: 40 / @vw;
                            }
                        }
                        .body2-2-1-3 {
                            width: 100%;
                            display: flex;
                            .body2-2-3-img-1 {
                                width: 80 / @vw;
                                height: 35 / @vh;
                                background: url('../../assets/img/canteenSelection/Frame 427320256.png')
                                    no-repeat;
                                background-size: 100% 100%;
                                color: #c5c5c5;
                                font-size: 19 / @vh;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                top: 312 / @vh;
                            }
                            .body2-2-3-img-2 {
                                width: 80 / @vw;
                                height: 35 / @vh;
                                background: url('../../assets/img/canteenSelection/Frame 427320256.png')
                                    no-repeat;
                                background-size: 100% 100%;
                                color: #c5c5c5;
                                font-size: 19 / @vh;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                top: 312 / @vh;
                            }
                            .body2-2-3-img-3 {
                                width: 80 / @vw;
                                height: 35 / @vh;
                                background: url('../../assets/img/canteenSelection/Frame 427320256.png')
                                    no-repeat;
                                background-size: 100% 100%;
                                color: #c5c5c5;
                                font-size: 19 / @vh;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                top: 312 / @vh;
                            }
                            .body2-2-3-img-4 {
                                width: 80 / @vw;
                                height: 35 / @vh;
                                background: url('../../assets/img/canteenSelection/Frame 427320256.png')
                                    no-repeat;
                                background-size: 100% 100%;
                                color: #c5c5c5;
                                font-size: 19 / @vh;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                top: 312 / @vh;
                            }
                        }
                    }
                    .body2-2-2 {
                        width: 100%;
                        height: 35%;
                        .body2-2-2-img {
                            width: 100%;
                            height: 100%;
                            background: url('../../assets/img/canteenSelection/Frame 427320639.png')
                                no-repeat;
                            background-size: 97% 270 / @vh;
                            margin-top: 8 / @vh;
                            margin-left: 15 / @vw;
                            display: flex;
                            flex-direction: row;
                            .body2-2-2-img-1 {
                                margin-top: 65 / @vh;
                                margin-left: 20 / @vw;
                                width: 50%;
                                height: 200 / @vh;
                                display: flex;
                                flex-direction: row;
                                .body2-2-2-img-1-1 {
                                    width: 200 / @vw;
                                    height: 100%;
                                    .body2-2-2-img-1-1-1 {
                                        margin-left: 30 / @vw;
                                        color: rgba(170, 183, 202, 1);
                                        margin-top: 20 / @vh;
                                        span {
                                            font-size: 28 / @vh;
                                            color: rgba(246, 78, 78, 1);
                                        }
                                    }
                                    .body2-2-2-img-1-1-2 {
                                        margin-top: 20 / @vh;
                                        margin-left: 30 / @vw;
                                        color: rgba(170, 183, 202, 1);
                                        span {
                                            font-size: 28 / @vh;
                                            color: rgba(66, 176, 255, 1);
                                        }
                                    }
                                }
                                .body2-2-2-img-1-2 {
                                    width: 100%;
                                    height: 92%;
                                }
                            }
                            .body2-2-2-img-2 {
                                margin-top: 65 / @vh;
                                width: 47%;
                                height: 200 / @vh;
                                .body2-2-2-img-swiper {
                                    width: 75%;
                                    height: 92%;
                                    position: relative;
                                    left: 25 / @vw;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
                .body2-3 {
                    width: 22%;
                    height: 100%;
                    .body2-3-img {
                        width: 100%;
                        height: 100%;
                        background: url('../../assets/img/canteenSelection/Frame 427320640.png')
                            no-repeat;
                        background-size: 405 / @vw 820 / @vh;
                        margin-top: 1 / @vh;
                        .body2-3-img-1 {
                            width: 100%;
                            height: 450 / @vh;
                            .body2-3-img-1-1 {
                                position: relative;
                                top: 65 / @vh;
                                left: 10 / @vw;
                                width: 90%;
                                height: 100 / @vh;
                                color: rgba(170, 183, 202, 1);
                                display: flex;
                                //两端
                                justify-content: space-between;
                            }
                            .body2-3-img-1-2 {
                                position: relative;
                                top: 5 / @vh;
                                left: 2 / @vw;
                                width: 400 / @vw;
                                border: 1px solid rgba(85, 98, 119, 0.5);
                                border-bottom: none;
                                border-left: none;
                                border-right: none;
                                font-size: 13 / @vh;
                                color: rgba(170, 183, 202, 1);
                                p {
                                    margin-top: 10 / @vh;
                                    margin-left: 10 / @vw;
                                    span {
                                        color: #ffffff;
                                    }
                                }
                            }
                            .body2-3-img-1-3 {
                                width: 400 / @vw;
                                height: 260 / @vh;
                                .body2-3-img-1-3-charts {
                                    position: relative;
                                    bottom: 10 / @vh;
                                    left: 10 / @vw;
                                }
                            }
                        }
                        .body2-3-img-2 {
                            width: 100%;
                            height: 150 / @vh;
                            .body2-3-img-2-chart {
                                position: relative;
                                top: 8 / @vh;
                                width: 85%;
                                height: 150 / @vh;
                                margin-left: 10 / @vw;
                            }
                        }
                        .body2-3-img-3 {
                            width: 100%;
                            height: 170 / @vh;
                            .body2-3-img-3-1 {
                                width: 90%;
                                color: rgba(170, 183, 202, 1);
                                font-size: 10 / @vh;
                                position: relative;
                                display: flex;
                                //两端
                                justify-content: space-between;
                                top: 70 / @vh;
                                left: 10 / @vw;
                            }
                            .body2-3-img-3-2 {
                                width: 80%;
                                position: relative;
                                display: flex;
                                justify-content: space-between;
                                top: 80 / @vh;
                                left: 30 / @vw;
                                img {
                                    width: 100 / @vw;
                                    height: 100 / @vh;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
