var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"djt"},[(!_vm.isShow)?_c('div',{staticClass:"dt"},[_c('div',{staticClass:"bg"},[_vm._m(0),_c('div',{staticClass:"body"},[_vm._m(1),_c('div',{staticClass:"body2"},[_c('div',{staticClass:"body2-1"},[_c('div',{staticClass:"wz",on:{"click":_vm.getst}},[_c('p',[_vm._v("独五食堂")])]),_c('div',{staticClass:"tb"})])])])])]):_vm._e(),(_vm.isShow)?_c('div',{staticClass:"st"},[_c('div',{staticClass:"bg"},[_vm._m(2),_c('div',{staticClass:"body"},[_vm._m(3),_c('div',{staticClass:"body2"},[_c('div',{staticClass:"body2-1"},[_c('div',{staticClass:"body2-1-img"},[_vm._m(4),_c('div',{staticClass:"body2-1-img-2"},[_c('dv-charts',{staticClass:"body2-1-img-2-charts",attrs:{"option":_vm.option}})],1)])]),_c('div',{staticClass:"body2-2"},[_c('div',{staticClass:"body2-2-1"}),_c('div',{staticClass:"body2-2-2"},[_c('div',{staticClass:"body2-2-2-img"},[_c('div',{staticClass:"body2-2-2-img-1"},[_vm._m(5),_c('div',[_c('dv-scroll-board',{staticClass:"body2-2-2-img-1-2",attrs:{"config":_vm.config}})],1)]),_c('div',{staticClass:"body2-2-2-img-2"},[_c('swiper',{staticClass:"body2-2-2-img-swiper",attrs:{"options":_vm.swiperOption}},[_c('swiper-slide',[_c('img',{attrs:{"width":"100%","height":"170","src":require("../../assets/img/canteenSelection/image6.png")}})]),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],1)],1)])])]),_c('div',{staticClass:"body2-3"},[_c('div',{staticClass:"body2-3-img"},[_c('div',{staticClass:"body2-3-img-1"},[_vm._m(6),_vm._m(7),_c('div',{staticClass:"body2-3-img-1-3"},[_c('dv-charts',{staticClass:"body2-3-img-1-3-charts",attrs:{"option":_vm.option2}})],1)]),_c('div',{staticClass:"body2-3-img-2"},[_c('dv-capsule-chart',{staticClass:"body2-3-img-2-chart",attrs:{"config":_vm.config2}})],1),_vm._m(8)])])])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"top-font1"},[_vm._v("2024年1月23日 星期五")]),_c('div',{staticClass:"top-font2"},[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/title.png")}})]),_c('div',{staticClass:"top-font3"},[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/tq1.png")}}),_vm._v(" 多云转阴 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body1"},[_c('div',{staticClass:"body1-1"},[_c('p',{staticClass:"p1"},[_vm._v("智慧设备数")]),_c('p',{staticClass:"p2"},[_vm._v("1234")])]),_c('div',{staticClass:"body1-2"},[_c('p',{staticClass:"p1"},[_vm._v("智慧场景数")]),_c('p',{staticClass:"p2"},[_vm._v("1234")])]),_c('div',{staticClass:"body1-3"},[_c('p',{staticClass:"p1"},[_vm._v("今日实时在岗人数")]),_c('p',{staticClass:"p2"},[_vm._v("1234")])]),_c('div',{staticClass:"body1-4"},[_c('p',{staticClass:"p1"},[_vm._v("今日总留样数")]),_c('p',{staticClass:"p2"},[_vm._v("1234")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"top-font1"},[_vm._v("2024年1月23日 星期五")]),_c('div',{staticClass:"top-font2"},[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/title.png")}})]),_c('div',{staticClass:"top-font3"},[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/tq1.png")}}),_vm._v(" 多云转阴 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body1"},[_c('div',{staticClass:"body1-1"},[_c('p',{staticClass:"p1"},[_vm._v("实时智慧设备数")]),_c('p',{staticClass:"p2"},[_vm._v("1234")])]),_c('div',{staticClass:"body1-3"},[_c('p',{staticClass:"p1"},[_vm._v("今日实时在岗人数")]),_c('p',{staticClass:"p2"},[_vm._v("1234")])]),_c('div',{staticClass:"body1-2"},[_c('p',{staticClass:"p1"},[_vm._v("今日排班人员")]),_c('p',{staticClass:"p2"},[_vm._v("1234")])]),_c('div',{staticClass:"body1-5"},[_c('p',{staticClass:"p1"},[_vm._v("今日留样餐次")]),_c('p',{staticClass:"p2"},[_vm._v("1234")])]),_c('div',{staticClass:"body1-4"},[_c('p',{staticClass:"p1"},[_vm._v("今日总留样餐次")]),_c('p',{staticClass:"p2"},[_vm._v("1234")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body2-1-img-1"},[_c('div',[_c('div',{staticClass:"body2-1-img-1-1"},[_c('div',{staticClass:"body2-1-img-1-1-1"},[_c('p',[_vm._v("留样餐次:"),_c('span',[_vm._v("早餐")])]),_c('p',[_vm._v("留样时间:"),_c('span',[_vm._v("2024/01/01")])])]),_c('div',{staticClass:"body2-1-img-1-1-2"},[_c('p',[_vm._v("留样数量:"),_c('span',[_vm._v("1")])]),_c('p',[_vm._v("留样负责人:"),_c('span',[_vm._v("李丹啊")])])])]),_c('div',{staticClass:"body2-1-img-1-2"},[_c('div',{staticClass:"body2-1-img-1-2-1"},[_c('div',{staticClass:"body2-1-img-1-2-1-1"},[_vm._v("01")]),_c('div',{staticClass:"body2-1-img-1-2-1-2"},[_vm._v("20g/菜品名称")]),_c('div',{staticClass:"body2-1-img-1-2-1-3"},[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/image4.png")}})]),_c('div',{staticClass:"body2-1-img-1-2-1-4"},[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/image5.png")}})])]),_c('div',{staticClass:"body2-1-img-1-2-1"},[_c('div',{staticClass:"body2-1-img-1-2-1-1"},[_vm._v("02")]),_c('div',{staticClass:"body2-1-img-1-2-1-2"},[_vm._v("20g/菜品名称")]),_c('div',{staticClass:"body2-1-img-1-2-1-3"},[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/image4.png")}})]),_c('div',{staticClass:"body2-1-img-1-2-1-4"},[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/image5.png")}})])]),_c('div',{staticClass:"body2-1-img-1-2-1"},[_c('div',{staticClass:"body2-1-img-1-2-1-1"},[_vm._v("03")]),_c('div',{staticClass:"body2-1-img-1-2-1-2"},[_vm._v("20g/菜品名称")]),_c('div',{staticClass:"body2-1-img-1-2-1-3"},[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/image4.png")}})]),_c('div',{staticClass:"body2-1-img-1-2-1-4"},[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/image5.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body2-2-2-img-1-1"},[_c('div',{staticClass:"body2-2-2-img-1-1-1"},[_vm._v(" 今日闯入 "),_c('br'),_c('span',[_vm._v("2")]),_vm._v(" 次 ")]),_c('div',{staticClass:"body2-2-2-img-1-1-2"},[_vm._v(" 本月闯入 "),_c('br'),_c('span',[_vm._v("66")]),_vm._v(" 次 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body2-3-img-1-1"},[_c('p',{staticStyle:{"color":"#FFFFFF"}},[_vm._v("17号冰箱")]),_c('p',[_vm._v("编号：1293812312")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body2-3-img-1-2"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/Vector.png")}}),_vm._v("温度："),_c('span',[_vm._v("12.2°C")]),_vm._v("（最高40°C、最低-20°C) ")]),_c('p',[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/Vector2.png")}}),_vm._v("湿度："),_c('span',[_vm._v("78.6%RH")]),_vm._v("（最高100%RH、最低10%RH) ")]),_c('p',[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/Vector3.png")}}),_vm._v("设备状态："),_c('span',[_vm._v("离线")])]),_c('p',[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/Vector4.png")}}),_vm._v("设备地址："),_c('span',[_vm._v("苏大独墅五食堂")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body2-3-img-3"},[_c('div',{staticClass:"body2-3-img-3-1"},[_c('p',[_vm._v("食堂负责人:"),_c('span',[_vm._v("李丹啊")])]),_c('p',[_vm._v("联系电话:"),_c('span',[_vm._v("13488888888")])])]),_c('div',{staticClass:"body2-3-img-3-2"},[_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/image7.png")}}),_c('img',{attrs:{"src":require("../../assets/img/canteenSelection/image8.png")}})])])
}]

export { render, staticRenderFns }